<template>
  <div>
    <PageHeader :title="portfolio.name" :sub-title="totalFloorArea" :no-border="true" />
    <TabView route-name="portfolio-view" :tabs="tabs" :loading="loadingAction.get">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeader';
import TabView from '@/components/TabView';
import { numberFormat } from '../../lib/helpers';

export default {
  name: 'PortfolioView',
  components: {
    PageHeader,
    TabView
  },
  data() {
    return {
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      portfolio: 'assetGroup/assetGroup',
      assets: 'assetGroup/assets',
      loadingAction: 'asset/loadingAction'
    }),
    tabs() {
      return [
        {
          name: 'reports',
          route: 'portfolio-reports',
          icon: 'fa-file-alt'
        },
        {
          name: 'assets',
          route: 'portfolio-assets',
          icon: 'fa-list'
        }
      ];
    },
    totalFloorArea() {
      const total = this.assets
        .filter(a => a.floorArea > 1)
        .reduce((acc, asset) => (asset.floorAreaUnit === 'imperial' ? asset.floorArea * 0.092903 : asset.floorArea) + acc, 0);

      const formattedTotal = numberFormat(total);

      return `${formattedTotal} m2`;
    }
  },
  async created() {
    await this.getAssetGroup({ id: this.$route.params.id });
    this.listAssets({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getAssetGroup: 'assetGroup/get',
      listAssets: 'assetGroup/listAssets'
    })
  }
};
</script>
