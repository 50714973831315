import { render, staticRenderFns } from "./PortfolioView.vue?vue&type=template&id=aee6c212&"
import script from "./PortfolioView.vue?vue&type=script&lang=js&"
export * from "./PortfolioView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports